import type IconType from 'types/icon';

const IconPreviousTime = ({ w, h, fill }: IconType) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${w} ${h}`}
    >
      <path
        d="M12 0L6.54545 3L12 6V4C17.4055 4 21.8182 8.045 21.8182 13C21.8182 17.955 17.4055 22 12 22C6.59455 22 2.18182 17.955 2.18182 13C2.18182 10.547 3.14509 8.43 4.90909 6.812L3.37527 5.407C1.21091 7.39 0 10.053 0 13C0 19.045 5.40545 24 12 24C18.5945 24 24 19.045 24 13C24 6.955 18.5945 2 12 2V0ZM9.71564 6.563L7.73891 7.438L10.5 12.438C10.4164 12.6158 10.3701 12.8065 10.3636 13V13.063L6.852 16.28L8.42182 17.72L11.9335 14.501H12C12.434 14.501 12.8502 14.343 13.1571 14.0617C13.464 13.7804 13.6364 13.3988 13.6364 13.001C13.6364 12.311 13.1356 11.738 12.4429 11.563L9.71564 6.563Z"
        fill={fill ?? '#6E6F76'}
      />
    </svg>
  );
};

export default IconPreviousTime;
